<template>
  <div>
    <window-header></window-header>
    <nav-form :nuevo="false" :editar="false" :eliminar="false"></nav-form>
    <section>
      <article style="height: 395px; width: 1200px">
        <img
          :src="
            formData.thumbnail && typeof formData.thumbnail == 'object'
              ? formData.thumbnail.imagen
              : require('./../assets/noimage.png')
          "
          style="
            position: absolute;
            width: 40px;
            height: 40px;
            top: 4px;
            left: 6px;
          "
          @dblclick="openPopupImagen"
        />
        <field
          name="codigo"
          widget="char"
          searchable
          label="Código"
          help="Código del modelo"
          placeholder="Cód. modelo"
          width="100px"
          style="top: 5px; left: 68px"
          :readonly="mode == 'edit'"
          inputStyle="font-weight:bold;text-align:center;"
        />
        <field
          name="nombre"
          widget="char"
          searchable
          label="Descripción"
          help="Descripción del modelo"
          required
          placeholder="Descripción del modelo"
          width="300px"
          style="top: 5px; left: 180px"
        />
        <field
          name="familia_id"
          searchable
          widget="m2o"
          dbAdapter="familia"
          label="Familia"
          placeholder="Familia"
          width="270px"
          style="top: 5px; left: 490px"
          required
        />

        <r-tabs
          ref="main-tabs"
          style="top: 40px; height: 355px"
          :wt="wt"
          :buttons="[
            'Movimientos',
            'F.Compras',
            'P.Ventas',
            'Cond. Ventas',
            'F.Ventas',
            'Depósitos',
            'Regularización',
            'Inventario',
          ]"
        >
          <!-- Movimientos -->
          <div class="tab">
            <field
              name="movimientos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                {
                  name: 'tipo',
                  header: 'Tipo',
                  type: 'select',
                  options: {
                    factura_cliente: 'FV - Factura de Cliente',
                    albaran_cliente: 'AV - Albaran de Cliente',
                    condicional_cliente: 'CV - Condicional de Cliente',
                    factura_proveedor: 'FC - Factura de Proveedor',
                    albaran_proveedor: 'AC - Albaran de Proveedor',
                    condicional_proveedor: 'CC - Condicional de Proveedor',
                    deposito_representante: 'DR - Deposito de Representante',
                    regularizacion: 'RG - Regularización',
                  },
                },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'origen_id',
                  header: 'Origen',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'destino_id',
                  header: 'Destino',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- F.Compras -->
          <div class="tab">
            <field
              name="compras"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'origen_id',
                  header: 'Proveedor',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- P.Ventas -->
          <div class="tab">
            <field
              name="pedidos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'destino_id',
                  header: 'Cliente',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Cond. Venta -->
          <div class="tab">
            <field
              name="condicionales"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'destino_id',
                  header: 'Cliente',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- F. Ventas -->
          <div class="tab">
            <field
              name="facturas"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'destino_id',
                  header: 'Cliente',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'divisa_id',
                  header: 'Divisa',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'codigo',
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Depósitos -->
          <div class="tab">
            <field
              name="depositos"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                { name: 'fecha', type: 'date2', header: 'Fecha' },
                { name: 'ndoc', header: 'Doc.' },
                {
                  name: 'destino_id',
                  header: 'Representante',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'unidades',
                  header: 'Unid.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'descuentos',
                  header: 'Dtos.',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                  sufix: '%',
                },
                {
                  name: 'precio',
                  header: 'Precio',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                /*{
                  name: 'totallinea',
                  header: 'Total Línea',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' }
                }*/
              ]"
            />
          </div>
          <!-- Regularizaciones -->
          <div class="tab">
            <field
              name="regularizaciones"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                {
                  name: 'fecha',
                  type: 'date2',
                  header: 'Fecha',
                },
                { name: 'ndoc', header: 'Doc.' },
                { name: 'descripcion', header: 'Descripción' },
                {
                  name: 'destino_id',
                  header: 'Almacén',
                  type: 'm2o',
                  primary: 'codigo',
                  readOnly: true,
                },
                {
                  name: 'unidades_real',
                  header: 'Unid. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_real',
                  header: 'Peso. reales',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'unidades_teorico',
                  header: 'Unid. teórico',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_teorico',
                  header: 'Peso teórico',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'unidades_dif',
                  header: 'Unid. discrep.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
                {
                  name: 'peso_dif',
                  header: 'Peso discrep.',
                  readOnly: true,
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.00' },
                },
              ]"
            />
          </div>
          <!-- Inventario -->
          <div class="tab">
            <field
              name="inventario2"
              widget="handsontable"
              :height="309"
              :width="1170"
              :minRows="13"
              style="top: 2px; left: 2px; width: 1170px"
              readonly
              :htSettings="{ columnSorting: true }"
              :columns="[
                {
                  name: 'almacen_id',
                  header: 'Almacen',
                  type: 'm2o',
                  primary: 'codigo',
                  label: 'nombre',
                  showCode: true,
                },
                {
                  name: 'unidad',
                  header: 'Unidad',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
                {
                  name: 'peso',
                  header: 'Peso',
                  type: 'numeric',
                  numericFormat: { pattern: '0,0.000' },
                },
              ]"
              :fields="['wt']"
            />
          </div>
        </r-tabs>
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        Listado
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";

export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    var self = this;
    return {
      title: "Consulta de artículos por modelo",
      dbAdapter: "modelo",
      primary: "codigo",
      fields: [
        { name: "thumbnail", fields: ["imagen"] },
        {
          name: "inventario",
          fields: [
            "wt",
            { name: "almacen_id", fields: ["codigo", "nombre"] },
            "unidad",
            "peso",
          ],
        },
      ],
      wt: 0,
      movimientos: [],
      inventario: [],
    };
  },
  methods: {
    actualizarMovimientos() {
      var self = this;
      self.loading = true;
      window.DB.action("articulo", "getMovimientos", {
        data: {
          modelo_id: self.itemId,
        },
      })
        .then(function (res) {
          self.movimientos = res.data;
          self.mostrarTablas();
        })
        .finally((_) => (self.loading = false));
    },
    mostrarTablas() {
      var self = this;
      let movs = self.$utils.misc.clone(self.movimientos);
      let m = movs.filter((x) =>
        self.wt == 1
          ? parseInt(x.wt || 0)
          : self.wt == 0
          ? !parseInt(x.wt || 0)
          : 1
      );
      self.$set(self.formData, "movimientos", self.$utils.misc.clone(m));
      let fp = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "factura_proveedor");
      self.$set(self.formData, "compras", fp);
      let pc = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "pedido_cliente");
      self.$set(self.formData, "pedidos", pc);
      let cc = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "condicional_cliente");
      self.$set(self.formData, "condicionales", cc);
      let fc = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "factura_cliente");
      self.$set(self.formData, "facturas", fc);
      let dr = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "deposito_representante");
      self.$set(self.formData, "depositos", dr);
      let rg = self.$utils.misc
        .clone(m)
        .filter((x) => x.tipo == "regularizacion");
      self.$set(self.formData, "regularizaciones", rg);
    },
    mostrarInventario() {
      var self = this;
      let inv = self.$utils.misc.clone(self.formData.inventario);
      inv = inv.filter((x) =>
        self.wt == 1
          ? parseInt(x.wt || 0)
          : self.wt == 0
          ? !parseInt(x.wt || 0)
          : 1
      );
      self.$set(self.formData, "inventario2", inv);
    },
    openPopupImagen() {
      var self = this;
      if (!self.itemId) return;
      var a = self.formData;
      if (
        self.win.children.length &&
        self.win.children.some((ch) => ch.window_name == "popup_imagen")
      ) {
        let popupImagen = self.win.children.find(
          (ch) => ch.window_name == "popup_imagen"
        );
        if (popupImagen.instance) popupImagen.instance.articulo = a;
        else popupImagen.opts.articulo = a;
      } else {
        self.app.openChildWindow("popup_imagen", self.win, {
          backdrop: false,
          resizable: true,
          articulo: a,
        });
      }
    },
  },
  mounted() {
    var self = this;

    self.$on("loadItem", function () {
      self.actualizarMovimientos();
      self.mostrarInventario();
      let popupImagen = self.win.children?.find(
        (ch) => ch.window_name == "popup_imagen"
      );
      if (popupImagen) {
        if (!self.itemId) return;
        var a = self.formData;
        if (popupImagen.instance) popupImagen.instance.articulo = a;
        else popupImagen.opts.articulo = a;
      }
    });

    self.$refs["main-tabs"].$refs.toolbar.addEventListener(
      "dblclick",
      function (e) {
        if (self.app.session.mode == "a") return;
        if (e.target == self.$refs["main-tabs"].$refs.toolbar) {
          self.wt = self.wt == 1 ? 2 : self.wt == 2 ? 0 : 1;
          self.mostrarTablas();
          self.mostrarInventario();
        }
      }
    );
  },
};
</script>